html, body {
  height: 100%;
  background: white;
  padding: 0;
  margin: 0;
  font-size: 100%;
}

html {
  height: 100%;
}

body {
  height: auto !important;
  height: 100%;
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-weight: 300;
  font-style: normal;
  font-size: 100%;
  font-family: $primary-font;
  color: $font-color;
  padding: 67px 0 0 0;
  margin: 0;
}

a {
  color: $link-color;

  &:hover,
  &:focus {
    color: $link-color;
  }
}

.container {
  max-width: 1300px;
}

#trigger {
  width: 100%;
  height: 0;
  float: left;
  position: relative;
}

section {
  width: 100%;
  display: block;
  overflow: auto;

  &.padded {
    padding: 30px 0;
  }
}

.page-title {
  background: $primary-color;
  background: linear-gradient(90deg, $primary-color, $secondary-color);
  width: 100%;
  height: auto;
  margin: 3px 0 0;

  h1 {
    padding: 15px 0;
    font-weight: 400;
    font-size: 30px;
    color: white;
    letter-spacing: 0;
    line-height: 40px;
  }
}

.text-block {
  p, ul, ol, blockquote {
    margin: 0 0 2em 0;
  }

  ul, ol {
    margin: 0 0 2em 0;
    padding: 0 0 0 18px;
  }

  img {
    max-width: 100%;
    display: block;
  }
}

.translate-plugin {
  position: fixed;
  bottom: 25px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 215px;
  padding: 10px 15px;
  height: 70px;
  background: white;
  border-radius: 5px;
  display: block;
  z-index: 500;
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);

  a.gflag {
    vertical-align: middle;
    font-size: 16px;
    padding: 1px 0;
    background-repeat: no-repeat;
    background-image: url(https://gtranslate.net/flags/16.png);

    &:hover {
      background-image: url(https://gtranslate.net/flags/16a.png);
    }
  }

  #goog-gt-tt {
    display: none !important;
  }

  .goog-te-banner-frame {
    display: none !important;
  }

  .goog-te-menu-value:hover {
    text-decoration: none !important;
  }

  #google_translate_element2 {
    display: none !important;
  }
}

img.content {
  border-radius: 16px 0;
}

strong {
  font-weight: bold;
}