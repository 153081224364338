.employee {
  display: block;
  width: 100%;
  float: left;
  margin-bottom: 30px;

  .employee-image {
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 0;

    img {
      position: relative;
      display: block;
      z-index: 0;
    }

    .employee-description {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      text-align: center;
      width: 100%;
      max-width: 300px;
      opacity: 0;
      visibility: hidden;
      transition: opacity ease-in-out 0.2s, visibility ease-in-out 0.2s;
      z-index: 100;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0);
      transition: background ease-in-out 0.3s;
      z-index: 10;
    }

    &:hover {
      &:before {
        background: rgba(255, 255, 255, 0.85);
      }

      .employee-description {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .employee-name {
    width: 100%;
    background: $primary-color;
    padding: 20px 12px;
    text-align: center;
    color: $secondary-color;

    h4 {
      margin: 0 0 7px 0;
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      font-size: 23px;
      line-height: 35px;
      letter-spacing: 0;
    }
  }
}