header {
  .navbar {
    .logo-small {
      position: relative;
      margin: 0 auto;

      img {
        max-width: 100%;
        max-height: 35px;
        display: block;
        position: relative;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  header {
    width: 100%;
    height: 152px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 1000;

    .navbar {
      .logo-small {
        img {
          max-height: 40px;
        }
      }
    }

    &.fixed-header {
      .navbar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: slideInDown;
        animation-name: slideInDown;

        .mr-auto {
          margin-right: 0 !important;
          margin-left: auto !important;
        }

        .logo-small {
          display: block;
          margin-right: auto !important;
        }
      }
    }
  }
}