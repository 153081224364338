#contact {
  padding-top: 45px;

  .center-block {
    text-align: center;

    h2 {
      font-size: 21px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0;
    }
  }

  .info {
    margin-bottom: 30px;
    padding: 0 30px;
  }

  .icon {
    margin: 0 auto 15px auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: $secondary-color;
    position: relative;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 35px;
    }
  }
}

#maps {
  width: 100%;
  height: 500px;
  overflow: hidden;
}