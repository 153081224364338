@media only screen and (min-width: 768px) {
  .block-info {
    .image {
      .keurmerk {
        max-width: 110px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  body {
    padding: 0;
  }
  .blocks {
    padding: 50px 0;

    .block-info {
      margin: 0;
      height: 100% !important;

      .image {
        .keurmerk {
          bottom: -113px;
          max-width: 129px;
        }
      }
    }
  }
  .page-title {
    height: 80px;

    h1 {
      font-size: 30px;
      line-height: 76px;
      padding: 0;
    }
  }
  #usp {
    .usp-description {
      margin-bottom: 30px;
    }
  }
  .banner-red {
    h3 {
      padding: 30px 265px 30px 0;
      text-align: left;
      float: left;
    }

    .btn-banner {
      position: absolute;
      right: 0;
      top: 50%;
      margin: 0;
    }
  }
  #contact {
    .info {
      margin-bottom: 0;
      padding: 0;
    }

    .icon {
      margin: 0 auto 25px auto;
    }
  }
  footer {
    .footer-info {
      text-align: left;
      padding: 60px 0;

      h4 {
        margin: 0 0 10px 0;
      }
    }

    .footer-disclaimer {
      text-align: left;
      padding: 25px 0;

      .footer-nav {
        float: left;

        li {
          width: auto;
          margin: 0 15px 0 0;
          padding: 0 15px 0 0;

          &:after {
            content: "";
            width: 1px;
            height: 14px;
            position: absolute;
            right: 0;
            top: 4px;
            background: #fff;
          }
        }
      }
    }
  }

  .translate-plugin {
    position: fixed;
    left: auto;
    right: 25px;
    bottom: 25px;
    margin: 0;
  }
}