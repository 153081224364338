.blocks {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 100;
  padding: 30px 0;

  .extra-info {
    margin: 0 0 30px 0;
    width: 100%;
    float: left;
    border-radius: 12px;
    padding: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .title {
      border-radius: 9px 9px 0 0;
      padding: 1.5rem;
      color: white;
      background: $primary-color;
      background: linear-gradient(90deg, $primary-color, $secondary-color);
    }

    .text {
      padding: 1.5rem 1.5rem 0.5rem;
      background: rgba(255, 255, 255, 0.75);
      color: $font-color;
      font-size: 18px;
    }

    h2 {
      color: white;
      font-weight: 600;
      letter-spacing: 0;
      font-size: 23px;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }
  }

  .block-info {
    width: 100%;
    height: auto;
    float: left;
    background: white;
    padding: 3px;
    margin: 0 0 30px 0;
    border-radius: 12px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h1, h2 {
      font-weight: 600;
      letter-spacing: 0;
      font-size: 23px;
      text-transform: uppercase;
      margin: 0 0 3px 0;
      padding: 13px 0;
      text-align: center;
      border-radius: 9px 0;
      background-color: $primary-color;
      color: white;
    }

    h3 {
      margin: 0;
      padding: 10px 0 4px;
      color: $secondary-color;
      font-weight: 700;
      font-size: 20px;
    }

    .bg-white {
      background: white;
      padding: 20px;
      width: 100%;
      display: block;
      float: left;
      position: relative;
    }

    .address {
      .fa-ul {
        margin-left: 30px;

        .fa-li {
          background-color: $secondary-color;
          left: -41px;
          padding: 2px 4px;
          border-radius: 6px;
          top: 11px;
        }

        li {
          color: $font-color;
          padding: 10px 0;
          font-size: 20px;

          i {
            color: white;
          }

          a {
            color: $font-color;
          }

          span {
            font-size: 16px;
            font-style: italic;
            display: block;
          }
        }
      }
    }

    .table-spreekuren {
      width: 100%;
      float: left;

      ul {
        border-bottom: 1px solid $font-color;
        margin: 0;
        padding: 0;
        list-style: none;
        background: white;
        display: block;
        overflow: auto;
        position: relative;
        width: 100%;

        li {
          float: left;
          width: 100%;
          display: block;
          padding: 10px 20px;

          span {
            float: left;
            width: 33.33333%;

          }

          &:nth-child(odd) {
            background: $grey-light;
          }

          &:first-child {
            background: $secondary-color;
            color: white;
            font-weight: 400;
          }
        }
      }
    }

    .spreekuren-extra {
      float: left;
      width: 100%;
      display: block;
      background: white;
      padding: 13px 20px;
      background: $grey-light;
      border-bottom: 1px solid $font-color;
      min-height: 265px;
      border-radius: 0 9px 0 0;

      h4 {
        line-height: 1.5;
        margin: -13px -20px 22px;
        font-size: 16px;
        font-weight: 600;
        background-color: $secondary-color;
        color: white;
        padding: 10px 20px;
        border-radius: 0 9px 0 0;
      }
    }

    .image {
      position: relative;

      img:first-child {
        border-radius: 0 9px;
      }

      .keurmerk {
        position: absolute;
        z-index: 100;
        max-width: 100px;
        bottom: -88px;
        right: 10px;
      }
    }
  }
}

#usp {
  text-align: center;

  .icon {
    width: 100px;
    height: 100px;
    background: $secondary-color;
    border-radius: 50%;
    margin: 0 auto;

    i {
      color: white;
      font-size: 50px;
      margin-top: 25px;
    }
  }

  .usp-description {
    position: relative;
    margin-bottom: 40px;

    h3 {
      font-size: 21px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0;
      padding: 15px 0;
      border-bottom: 1px solid $primary-color;
      margin: 0 0 15px;
    }
  }
}