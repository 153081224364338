footer {
  width: 100%;
  display: block;
  overflow: auto;
  background: $primary-color;
  background: linear-gradient(90deg, $primary-color, $secondary-color);
  color: $font-color;

  .footer-info {
    padding: 0 0 30px 0;
    text-align: center;
    color: white;

    .nav-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    h4 {
      font-weight: 700;
      padding: 0 0 10px;
      border-bottom: 1px solid white;
      margin: 30px 0 .5rem;
    }

    p {
      margin: 0;
      padding: 0;
    }

    a {
      color: white;
      text-decoration: none;

      &:hover,
      &:focus {
        color: white;
        text-decoration: underline;
      }
    }
  }

  .footer-disclaimer {
    background: white;
    padding: 25px 0 130px 0;
    text-align: center;

    p {
      margin: 0;
      padding: 0;
    }

    .footer-nav {
      margin: 0;
      padding: 0;
      list-style: none;
      float: none;

      li {
        float: left;
        margin: 0;
        padding: 0;
        position: relative;
        width: 100%;

        &:after {
          content: "";
          width: 0;
          height: 0;
        }

        a {
          color: $secondary-color;
          font-weight: 400;
        }

        &:last-child {
          margin: 0;
          padding: 0;

          &:after {
            width: 0;
            height: 0;
          }
        }
      }
    }
  }
}