.banner {
  width: 100%;
  position: relative;
  overflow: auto;
  display: block;

  .container {
    position: relative;
    height: 100%;
    overflow: auto;
  }
}

.banner-red {
  background: $primary-color;
  color: white;
  position: relative;

  h3 {
    font-size: 23px;
    font-weight: 600;
    letter-spacing: 1px;
    color: white;
    float: none;
    padding: 30px;
    text-align: center;
    width: 100%;
    margin: 0;
    display: block;
  }

  a {
    color: white;
  }

  .btn-banner {
    position: relative;
    overflow: auto;
    text-align: center;
    margin: 10px auto;
    font-weight: 700;
    font-size: 18px;
    line-height: 56px;
    height: 58px;
    display: block;
    padding-left: 35px;
    padding-right: 35px;
    transform: translateY(-50%);
    background: $secondary-color;
    text-decoration: none;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    transition: all ease-in-out 0.25s;
    z-index: 10;

    &:hover,
    &:focus {
      color: $primary-color;
    }
  }
}