nav {

  &.navbar {
    padding: 1rem 1rem;
  }

  &.fixed-top {
    position: fixed;
    top: 0;
    width: 100%;
    background: $primary-color;
  }

  button.navbar-toggler {
    background: transparent;
    border: 0;
    float: right;
    width: 36px;
    height: 25px;
    position: relative;
    margin: 10px 0 0 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      border-radius: 3px;
      width: 100%;
      background: white;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2) {
      top: 8px;
    }

    span:nth-child(3) {
      top: 16px;
    }
  }

  .navbar-toggler.open span:nth-child(1) {
    top: 8px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .navbar-toggler.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  .navbar-toggler.open span:nth-child(3) {
    top: 8px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

  .navbar-nav {
    li {
      border-bottom: 1px solid $grey-light;

      a {
        text-decoration: none;
        text-transform: uppercase;
        color: white;
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 60px;

        &:hover,
        &:focus {
          text-decoration: none;
          color: $secondary-color;
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .dropdown-menu {
    border: 0;
    border-radius: 0;
    background: $secondary-color;
    padding: 0;
    margin: 0 -1rem -1px -1rem;
    border-top: 1px solid white;
    border-bottom: 1px solid white;

    li {
      a {
        padding: 0 1rem;
        font-weight: 300;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  nav {
    box-shadow: -4px 4px 5px 0 rgba(0, 0, 0, .03);

    .navbar-nav {
      li {
        padding: 0 22px;
        border-bottom: 0;

        a {
          transition: color ease-in-out 0.2s;
        }

        &:first-child {
          padding: 0 22px 0 0;
        }
      }
    }
  }
  nav {
    &.navbar {
      padding: .5rem 1rem;
    }

    &.fixed-top {
      height: 60px;
      top: 92px;
      position: absolute;
    }

    .logo-small {
      display: none;
    }

    .navbar-nav {
      li {
        &.dropdown {
          ul.dropdown-menu {
            padding: 0 0 10px;
            margin: -5px 0 0 22px;
            background: $primary-color;
            border-radius: 0;
            border-top: none;
            border-bottom: none;

            li {
              padding: 0;

              a {
                display: block;
                line-height: 30px;
                padding: 3px 20px;
                color: white;
                cursor: pointer;
                font-weight: 300;

                &:hover,
                &:focus {
                  color: $secondary-color;
                }
              }
            }
          }

          &:hover {
            .dropdown-menu {
              display: block;
            }
          }
        }
      }
    }
  }
}