#slider-lg {
  width: 100%;
  min-height: calc(100vh - 350px);
  overflow: hidden;
  position: relative;

  .owl-stage-outer,
  .owl-stage,
  .owl-item {
    height: 100%;
  }

  #slides {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

  }

  .owl-slide {
    width: 100%;
    height: 100%;
    position: relative;

    .image {
      width: 100%;
      min-height: 625px;
      height: 100%;
    }
  }
}