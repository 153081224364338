$primary-color: #273766;
$secondary-color: #27a1df;

$font-color: #232119;
$link-color: #27a1df;

$grey-dark: #3e4144;
$grey: #45484b;
$grey-light: #f6f6f6;

$primary-font: 'Source Sans Pro', sans-serif;
