.header-top {
  position: relative;
  width: 100%;
  height: 92px;
  background: white;
  border-bottom: 1px solid $grey-light;
  display: none;

  .logo {
    display: block;
    float: left;
    height: auto;
    max-height: 100%;
    margin: 10px 0 0 0;

    img {
      max-width: 342px;
      max-height: 72px;
      display: block;
    }
  }

  .header-right {
    float: right;
    position: relative;

    ul {
      margin: 15px 0 0 0;
      padding: 0;
      list-style: none;
      float: right;

      li {
        float: left;
        margin-right: 25px;
        position: relative;
        background: lighten($secondary-color, 40%);
        border-radius: 6px;
        padding: 10px 15px 10px 55px;

        i {
          position: absolute;
          left: 10px;
          top: 13px;
          font-size: 32px;
          color: white;
        }

        h6 {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          font-style: normal;
          letter-spacing: 0;
          text-transform: none;
          display: block;
          margin: 0;
          padding: 0;
        }

        p {
          color: $primary-color;
          line-height: 20px;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          display: block;
          margin: 0;
          padding: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .header-top {
    display: block;
  }
}