@media only screen and (max-width: 767px) {
  .blocks {
    .block-info {
      .spreekuren-extra {
        min-height: 0;
        padding: 1rem 20px 6px;
        border-radius: 0;

        h4 {
          border-radius: 0;
        }
      }
    }
  }
}